
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'
import ApiStatusPending from '@/components/home/ApiStatusPending'
import ApiStatusError from '@/components/home/ApiStatusError'
import ResultsStageResults from '@/components/home/ResultsStageResults'
import ResultsIndividual from '@/components/home/ResultsIndividual'
import ResultsPoints from '@/components/home/ResultsPoints'
import ResultsClimber from '@/components/home/ResultsClimber'
import ResultsYouth from '@/components/home/ResultsYouth'

export default {
  components: {
    ApiStatusPending,
    ApiStatusError,
    ResultsStageResults,
    ResultsIndividual,
    ResultsPoints,
    ResultsClimber,
    ResultsYouth
  },
  data() {
    return {
      api_status: 'PENDING',
      activeTab: 0,
      multiline: true,
      resultsRaceStageResultsTopTen: [],
      resultsIndividualTopTen: [],
      resultsPointsTopTen: [],
      resultsclimberTopTen: [],
      resultsYouthTopTen: []
    }
  },
  computed: {
    ...settingsStateMapGetter(),
    baseTabs() {
      return this.getTabs()
    },
    tabs() {
      const tabs = [...this.baseTabs]
      return tabs
    }
  },
  created() {
    this.loadAsyncData()
  },
  methods: {
    async loadAsyncData() {
      const { response: res, error: err } = await withCatch(() =>
        this.$raceApi.getRaceRankingsTopTen(
          this.selectedRace.slug,
          this.selectedRace.current_stage_number
        )
      )

      if (err) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      const { data } = res

      const raceStageResultsTopTen = []
      const individualTopTen = []
      const pointsTopTen = []
      const climberTopTen = []
      const youthTopTen = []

      if (data.length !== 0) {
        data.forEach((item, index) => {
          if (item.race_rankingtype.ranking_title === 'Individual') {
            individualTopTen.push(item)
          }
          if (item.race_rankingtype.ranking_title === 'Points') {
            pointsTopTen.push(item)
          }
          if (item.race_rankingtype.ranking_title === 'Climber') {
            climberTopTen.push(item)
          }
          if (item.race_rankingtype.ranking_title === 'Youth') {
            youthTopTen.push(item)
          }
        })
      }

      this.resultsIndividualTopTen = individualTopTen
      this.resultsPointsTopTen = pointsTopTen
      this.resultsclimberTopTen = climberTopTen
      this.resultsYouthTopTen = youthTopTen

      this.api_status = 'SUCCESS'

      const { response: res2, error: err2 } = await withCatch(() =>
        this.$raceApi.getRaceStageResultsTopTen(
          this.selectedRace.slug,
          this.selectedRace.current_stage_number
        )
      )

      if (err2) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      if (res2.data.length !== 0) {
        res2.data.forEach((item, index) => {
          raceStageResultsTopTen.push(item)
        })
      }

      this.resultsRaceStageResultsTopTen = raceStageResultsTopTen

      this.api_status = 'SUCCESS'
    },
    getTabs() {
      const tabs = []

      tabs.push({
        title:
          this.$t('ranking_title_stages') +
          ' ' +
          this.selectedRace.current_stage_number,
        label:
          this.$t('ranking_title_stages') +
          ' ' +
          this.selectedRace.current_stage_number,
        label_image: 'https://assets.gokoppa.com/cycling/jerseys/cyclist.svg',
        component: 'Stage Result',
        displayed: true
      })

      this.selectedRace.rankingtypes.forEach(item => {
        const tab = {
          title: this.$t(item.ranking_title_locale),
          label: this.$t(item.ranking_title_locale),
          label_image: item.image_url,
          component: item.ranking_title,
          displayed: true
        }

        if (item.ranking_title !== 'Team') {
          tabs.push(tab)
        }
      })

      return tabs
    }
  }
}
