
export default {
  props: {
    ranking: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isEmpty: false,
      hasMobileCards: false
    }
  }
}
