import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=fb346544&"
import script from "./Results.vue?vue&type=script&lang=js&"
export * from "./Results.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResultsStageResults: require('/app/components/results/StageResults.vue').default,ResultsIndividual: require('/app/components/results/Individual.vue').default,ResultsPoints: require('/app/components/results/Points.vue').default,ResultsClimber: require('/app/components/results/Climber.vue').default,ResultsYouth: require('/app/components/results/Youth.vue').default})
