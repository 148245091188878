
import ApiStatusPending from '@/components/home/ApiStatusPending'
import ApiStatusError from '@/components/home/ApiStatusError'
import { settingsStateMapGetter } from '@/services/settingsService'
import { withCatch } from '@/utils'

export default {
  components: {
    ApiStatusPending,
    ApiStatusError
  },
  data() {
    return {
      stageResults: [],
      stageType: '',
      isEmpty: false,
      hasMobileCards: false,
      api_status: 'PENDING'
    }
  },
  computed: {
    ...settingsStateMapGetter()
  },
  created() {
    this.loadAsyncData()
  },
  methods: {
    async loadAsyncData() {
      const { response: res, error: err } = await withCatch(() =>
        this.$raceApi.getRaceStageResults(
          this.selectedRace.slug,
          this.$route.params.number
        )
      )

      if (err) {
        this.$sentry.captureException(err)
        this.api_status = 'ERROR'
        return
      }

      const { data } = res

      if (data.length === 0) {
        this.isEmpty = true
      }

      data.forEach((item, index) => {
        if (this.stageType === '') {
          this.stageType = item.stage.stagetype.name_locale
        }
      })

      this.stageResults = data

      this.api_status = 'SUCCESS'
    }
  }
}
